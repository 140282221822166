<template>
  <div class="support-container">
    <SectionTitle title="Về chúng tôi" />
    <div class="support">
      <div
        v-for="item in INTRODUCE_LIST"
        :id="`support-${item?.name}`"
        :key="item.name"
        class="support__item"
        :class="item?.type"
      >
        <template v-if="item.hide !== '0'">
          <NuxtLink :to="item.url" class="support__item--link">
            <BaseImg class="icon" :src="PATH_IMG_SECTION_INTRODUCE + item.icon" :alt="item.name" />
            <p class="text">{{ item.name }}</p>
          </NuxtLink>
        </template>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import SectionTitle from '~/components/mobile/common/section-title.vue'
import { PATH_IMG_SECTION_INTRODUCE } from '~/constants/path'
import { PAGE_URLS } from '~~/config/page-url'

const { $config } = useNuxtApp()
const INTRODUCE_LIST = [
  {
    name: 'Giới thiệu',
    icon: 'icon-introduce.svg',
    url: `${PAGE_URLS.INTRODUCTION}`
  },
  {
    name: 'Quy định',
    icon: 'icon-policy.svg',
    url: `${PAGE_URLS.RULE}`
  },
  {
    name: 'Bảo mật',
    icon: 'icon-privacy.svg',
    url: `${PAGE_URLS.SECURITY}`
  },
  {
    name: 'Hướng dẫn',
    icon: 'icon-desc.svg',
    url: `${PAGE_URLS.GUIDE}`
  },
  {
    name: 'Tin tức',
    icon: 'icon_news.svg',
    url: `${PAGE_URLS.NEWS}`,
    type: 'news',
    hide: $config.SHOW_NEWS
  }
]
</script>
<style scoped lang="scss" src="public/assets/scss/components/mobile/pages/home/section-introduce/index.scss"></style>
